import React from 'react';
import { IFooterLinksGroup, IFooterLink } from 'components/Footer/FooterLinkObjects';
import Link from 'next/link';

interface IFooterLinksList {
  linksGroup: IFooterLinksGroup;
}

const FooterLinksList = ({ linksGroup }: IFooterLinksList) => {
  const links = linksGroup.links.map((item: IFooterLink) => {
    return (
      <li className="new-footer__link-item" key={item.linkId}>
        {item.linkUrl ? (
          <Link className="new-footer__link-item-a" href={item.linkUrl}>
            {item.linkLabel}
          </Link>
        ) : (
          <span className="new-footer__link-item-span">{item.linkLabel}</span>
        )}
      </li>
    );
  });

  return <ul className="new-footer__links-list">{links}</ul>;
};

export default FooterLinksList;
