import React from 'react';
import { FooterLinkObjects, IFooterLinksGroup } from './FooterLinkObjects';
import FooterLinksList from './FooterLinksList';
import Link from 'next/link';
import Image from 'next/image';
import Script from 'next/script';

const Footer = () => {
  const linkLists = FooterLinkObjects.map((linksGroup: IFooterLinksGroup) => {
    return <FooterLinksList key={linksGroup.groupId} linksGroup={linksGroup} />;
  });

  return (
    <footer className="new-footer">
      <div className="new-container">
        <div className="new-footer__section">
          <div className="new-footer__logo-box">
            <Link className="new-footer__logo-link" href="/">
              <Image
                width={149}
                height={29}
                className="new-footer__logo"
                src="/images/global/logo-dfa-light.svg"
                alt="df-alliance"
              />
            </Link>
            <div className="new-footer__social">
              <Link className="new-footer__social-link" href="https://www.facebook.com/DigitalFreightAlliance_DPWorld">
                <Image width={20} height={20} src="/images/global/social/icon-facebook.svg" alt="facebook" />
              </Link>
              <Link className="new-footer__social-link" href="https://x.com/D_F_Alliance">
                <Image width={22} height={22} src="/images/global/social/icon-twitter.svg" alt="twitter" />
              </Link>
              <Link
                className="new-footer__social-link"
                href="https://www.linkedin.com/company/digital-freight-alliance"
              >
                <Image width={24} height={24} src="/images/global/social/icon-linkedin.svg" alt="linkedin" />
              </Link>
            </div>
          </div>
          <div className="new-footer__links-box">{linkLists}</div>
        </div>
        <div className="new-footer__copyrights">2023 © DF Alliance. All rights reserved.</div>
      </div>
    </footer>
  );
};

export default Footer;
